/* CustomCheckboxTree.css */

  
  /* Optional: Custom styles for checked checkboxes if you want */
  
  /* Optional: Custom styles for expanded and collapsed nodes */
 .react-checkbox-tree .rct-node-parent {
    color: #0d5391; /* Example: Change color for parent nodes */
    font-family: serif;
 }

 /*   Example: Change color for leaf nodes */ 
.react-checkbox-tree .rct-node-leaf {
    color: green;
    font-family: serif;
  } 

  .rct-node-clickable:hover {
    background: rgba(51, 51, 204, 0.1);
  }
  .rct-node-clickable:focus {
    outline: 0;
    background: rgba(51, 51, 204, 0.2);
  }
  
  .rct-node-icon {
    color: #bf9001;
  }
