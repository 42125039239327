/* Define the page size and margins for printing */
@page {
    size: A4; /* Set the page size to A4 or another standard size */
    margin: 15mm; /* Adjust the margins as needed */
}
  
/* Apply additional styles for the printed content */
@media print {
/* Define additional styles for printing, if necessary */
    body {
        margin: 0;
    }
}
  